import {Metric, onCLS, onFID, onLCP} from 'web-vitals';

function sendToGoogleAnalytics(result: Metric) {
  window.gtag('event', result.name, {
    value: result.delta,
    metric_id: result.id,
    metric_value: result.value,
    metric_delta: result.delta,
  });
}

onCLS(sendToGoogleAnalytics);
onFID(sendToGoogleAnalytics);
onLCP(sendToGoogleAnalytics);
